import React from 'react'
import Layout from '../layouts'

class ContactUs extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		return (
			<Layout head={{ title: 'Contact Us' }}>
				<div className="banner-background">
					<center>
						<h1>Contact Us</h1>
					</center>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<form
								action="/thank-you"
								method="post"
								data-form-id="5c6e6ef98ba9a30a93a2a06a"
								webriq="true"
							>
								<div className="form-group">
									<label>Your Name:</label>
									<input
										className="form-control"
										type="text"
										name="name"
										required=""
									/>
								</div>
								<div className="form-group">
									<label>Your Email: *</label>
									<input
										className="form-control"
										type="text"
										name="email"
										required=""
									/>
								</div>
								<div className="form-group">
									<label>Your Phone Number with Area Code</label>
									<input
										className="form-control"
										type="text"
										name="phone-number"
										required=""
									/>
								</div>
								<div className="form-group">
									<label>Your Message</label>
									<textarea
										className="form-control"
										rows="10"
										name="comments"
									/>
								</div>
								<div className="webriq-recaptcha mb-3" />
								<div className="form-group">
									<button className="btn btn-primary btn-block" type="submit">
										Submit
									</button>
								</div>
							</form>
						</div>
						<div className="col-md-6">
							<div className="form-group contact-us">
								<h1>Contact Us</h1>
								<i class="fas fa-phone" />
								&nbsp;&nbsp;
								<label>Tel:&nbsp;</label>
								<a href="tel:719.238.6211">719.238.6211</a>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ContactUs
